const locationMapper = require('./locationMapper')
const likeButton = require('./likeButton')

const booter = require('./booter')

function main () {
  const config = window.config

  locationMapper.init({
    locations: config.locations,
    mapContainer: '.location__maps',
    googleMapsApiKey: config.googleMapsApiKey,
    domain: config.domain,
    showCount: true
  })

  likeButton.init(config)
}

booter.boot(main)
