const util = require('./util')

class LikeButton {
  constructor (config) {
    this.saveUrl = `/${config.domain}/like`
    // TODO: classes in config
    this.likeCount = document.querySelector('.social-icons__likes')
    this.heartButton = document.querySelector('.social-icons__heart--available')
    if (this.heartButton) {
      this.heartButton.addEventListener('click', this.saveLike.bind(this), { once: true })
    }
  }

  saveLike (event) {
    util.addClass(this.heartButton, 'social-icons__heart--active')
    util.postJson(this.saveUrl).then(json => {
      const count = parseInt(this.likeCount.innerText, 10) || 0
      this.likeCount.innerText = 1 + count
    }).catch(ex => {
      if (ex.status === 409) {
        return console.log('Unable to like this profile. You have liked this profile already.')
      }
      console.log('Unable to like this profile. Please try again.')
      window.Sentry && window.Sentry.captureException(ex)
    })
  }
}

module.exports = {
  init: (config) => new LikeButton(config)
}
